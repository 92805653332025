<template>
  <div>
    <div v-if="type == 101">
      <div class="compont_p">
        我的退货单
        <!-- <div class="btn flex_c_c" @click="type = 102">添加退货</div> -->
      </div>
      <!-- <div class="address-info">退货收件地址：{{ returnAddressInfo.lxr }} {{ returnAddressInfo.lxdh }} {{ returnAddressInfo.address }}</div> -->
      <el-form :inline="true" :model="form" style="margin-top: 20px">
        <el-form-item label="关键字:">
          <el-input clearable v-model="form.kw" placeholder="商品名称/订单编号"></el-input>
        </el-form-item>
        <el-form-item label="退回单状态:">
          <el-select clearable v-model="form.status" placeholder="请选择">
            <el-option v-for="item in returnStatus" :key="item.id" :label="item.title" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="custombtn" @click="getReturn">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" class="mytable">
        <el-table-column prop="p_order" label="订单编号" width="200" align="center" />
        <el-table-column prop="orderno" label="退货单编号" width="150" align="center" />
        <!-- <el-table-column prop="reason_arr" label="退货原因" align="center">
          <template slot-scope="scope">
            <span v-for="item in scope.row.reason_arr" :key="item">{{ item }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="total_price" label="货物价格" width="100" align="center" />
        <el-table-column prop="status_str" label="退货单状态" width="100" align="center" />

        <!-- <el-table-column prop="tkzt" label="退款状态" align="center" /> -->
        <!-- <el-table-column prop="addtime" label="添加时间" align="center" /> -->
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleClick(scope.row)">查看</el-button>
            <el-button v-if="scope.row.btn_cancle == 101" size="mini" @click="cancalApply(scope.row)">取消退款</el-button>
            <el-button v-if="scope.row.btn_logistics == 101" size="mini" @click="readLogistic(scope.row)">查看物流</el-button>
            <el-button size="mini" v-if="scope.row.btn_reg == 101" @click="waybillNumber(scope.row)">运单号</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
    </div>

    <!-- <div v-if="type == 102">
      <div class="compont_p">
        添加退货
        <div class="btn flex_c_c btn1" @click="back">返回上级</div>
      </div>
      <div class="form_wrap">
        <el-form :model="formInline" label-width="110px" :rules="rules" ref="form">
          <el-form-item label="退货订单编号：">
            <el-input v-model="$route.params.orderno" disabled placeholder="退货订单编号" style="width: 220px"></el-input>
          </el-form-item>
          <el-form-item label="勾选退货商品：">
            <el-table :data="returnData" @selection-change="selectionChange">
              <el-table-column type="selection" width="55" :selectable="selectable"> </el-table-column>
              <el-table-column prop="title" label="商品名称"> </el-table-column>
              <el-table-column prop="manufacturer" label="生产企业"> </el-table-column>
              <el-table-column prop="specification" label="商品规格"> </el-table-column>
              <el-table-column prop="price" label="价格"> </el-table-column>
              <el-table-column prop="number" label="购买数量"> </el-table-column>
              <el-table-column prop="outstock_number" label="出库数量"> </el-table-column>
              <el-table-column label="退货数量" width="200">
                <template slot-scope="{ row }">
                  <el-input-number v-model="row.number1" :min="row.outstock_number == 0 ? 0 : 1" :max="Number(row.outstock_number)"></el-input-number>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="退货原因：" prop="reason_arr">
            <el-select v-model="reasonArr" multiple placeholder="请选择" @change="changeReason">
              <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传凭证：" prop="image">
            <upload :limit="4" @successUrl="successUrl" @deleteImage="deleteImage"></upload>
          </el-form-item>
          <el-form-item label="退货原因描述：">
            <el-input type="textarea" style="width: 520px" :rows="5" v-model="formInline.reason"></el-input>
          </el-form-item>
        </el-form>
        <div class="sub_btn flex_c_c" @click="onSubmit">提交退货</div>
      </div>
    </div> -->

    <el-dialog title="退货申请详情" :visible.sync="dialogVisible" width="60%">
      <el-descriptions :column="2">
        <el-descriptions-item label="退货单编号">{{ info.orderno }}</el-descriptions-item>
        <el-descriptions-item label="订单时间">{{ info.order_time }}</el-descriptions-item>
        <el-descriptions-item label="退款金额">{{ info.return_amount }}</el-descriptions-item>
        <el-descriptions-item label="退货状态">{{ info.status_str }}</el-descriptions-item>
        <el-descriptions-item label="退货图片" v-if="info.logos && info.logos.length > 0">
          <div v-for="item in info.logos" :key="item.id">
            <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="srcList"> </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="退货原因">
          <span v-for="item in info.reason_arr" :key="item">{{ item }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="退货原因描述"> {{ info.reason }} </el-descriptions-item>
        <el-descriptions-item label="管理员回复">{{ info.shbz }} </el-descriptions-item>
        <el-descriptions-item v-if="info.kdname" label="退货快递公司">{{ info.kdname }} </el-descriptions-item>
        <el-descriptions-item v-if="info.kdno" label="退货运单号">{{ info.kdno }} </el-descriptions-item>
        <el-descriptions-item label="收件人">{{ info.returnInfo ? info.returnInfo.lxr : "--" }} </el-descriptions-item>
        <el-descriptions-item label="联系电话">{{ info.returnInfo ? info.returnInfo.lxdh : "--" }} </el-descriptions-item>
        <el-descriptions-item label="收货地址">{{ info.returnInfo ? info.returnInfo.address : "--" }} </el-descriptions-item>
      </el-descriptions>

      <el-table :data="info.goods" style="width: 100%">
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <el-image :src="scope.row.image"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="商品名称"> </el-table-column>
        <el-table-column prop="specification" label="商品规格"> </el-table-column>
        <el-table-column prop="manufacturer" label="生产企业"> </el-table-column>
        <el-table-column prop="price" label="价格"> </el-table-column>
        <!-- <el-table-column prop="stock_num" label="采购数量"> </el-table-column> -->
        <el-table-column prop="pihao" label="批号"> </el-table-column>
        <el-table-column prop="yxq" label="有效期"> </el-table-column>
        <!-- <el-table-column prop="pihao" label="批号"> </el-table-column> -->
        <el-table-column prop="number" label="出库数量" align="center"> </el-table-column>
        <el-table-column prop="th_sl" label="退货数量" align="center"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="填写运单号" :visible.sync="dialogYundanVisible" width="30%">
      <el-form label-width="80px" :model="formLabelAlign" ref="formLabelAlign" :rules="formRules">
        <el-form-item label="快递公司" prop="wldw">
          <el-input v-model="formLabelAlign.wldw"></el-input>
        </el-form-item>
        <el-form-item label="物流单号" prop="wldh">
          <el-input v-model="formLabelAlign.wldh"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('formLabelAlign')">取 消</el-button>
        <el-button type="primary" @click="confirmFn('formLabelAlign')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";
// import upload from "@/components/upload.vue";
export default {
  components: {
    pagination,
    // upload,
  },
  data() {
    return {
      type: 101,
      form: {
        kw: "",
        status: "",
      },
      returnData: [],
      formInline: {
        id: "",
        reason: "",
        image: [],
        goods: [],
        reason_arr: [],
      },
      tableData: [],
      returnStatus: [],
      reasonArr: [],
      options: [],
      optionsObj: {},
      total: 0,
      page: 1,
      page_size: 10,
      info: {},
      dialogVisible: false,
      dialogYundanVisible: false,
      returnAddressInfo: {},
      srcList: [],
      selectionList: [],
      rules: {
        reason_arr: [{ required: true, message: "请选择退货原因", trigger: "change" }],
        image: [{ required: true, message: "请上传凭证", trigger: "change" }],
      },
      formLabelAlign: {
        id: "",
        orderno: "",
        wldw: "",
        wldh: "",
      },
      formRules: {
        wldw: [{ required: true, message: "请输入快递公司", trigger: "change" }],
        wldh: [{ required: true, message: "请输入物流单号", trigger: "change" }],
      },
    };
  },
  created() {
    this.getReturnAddress();
    this.getReturn();
    this.getStatus();
    this.$api("account.getReturnReason").then((res) => {
      this.options = res.data;
      this.optionsObj = {}; //清空

      this.options.map((item) => {
        this.optionsObj[item.value] = { ...item };
      });
    });
    if (this.$route.params.orderno) {
      this.type = 102;
      this.formInline.id = this.$route.params.id;
      this.formInline.orderno = this.$route.params.orderno;
      this.getDetail();
    }
  },
  methods: {
    getStatus() {
      this.$api("account.getReturnStatus").then((res) => {
        if (res.code == 200) {
          this.returnStatus = res.data;
        }
      });
    },
    getReturn() {
      this.$api("account.getReturn", {
        ...this.form,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    getReturnAddress() {
      this.$api("account.returnAddress", {}).then((res) => {
        this.returnAddressInfo = res.data;
      });
    },
    handleClick(row) {
      this.$api("account.getReturnDel", { id: row.id }).then((res) => {
        if (res.code == 200) {
          this.info = res.data;
          console.log("退货订单详情-----", this.info);
          this.dialogVisible = true;
        }
      });
    },
    waybillNumber(row) {
      console.log("-----", row);
      this.formLabelAlign.id = row.id;
      this.formLabelAlign.orderno = row.orderno;
      if (row.wldh && row.wldw) {
        this.formLabelAlign.wldh = row.wldh;
        this.formLabelAlign.wldw = row.wldw;
      }
      this.dialogYundanVisible = true;
    },
    getDetail() {
      this.$api("account.getOrderDel", { orderno: this.$route.params.orderno, id: this.$route.params.id }).then((res) => {
        // res.data.goods.forEach((e) => (e.number1 = 0));
        this.returnData = res.data.goods.map((v) => {
          this.$set(v, "number1", 0);
          return v;
        });
        // this.returnData = res.data.goods;
      });
    },
    back() {
      this.type = 101;
      this.getReturn();
    },
    cancalApply(row) {
      this.$alert("确定取消退款申请吗？", "取消退款申请", {
        confirmButtonText: "确定",
        callback: () => {
          this.$api("account.getReturnCancle", { id: row.id, orderno: row.orderno }).then((res) => {
            if (res.code == 200) {
              this.$message.success("取消申请成功");
              this.getReturn();
            }
          });
          // this.$message({
          //   type: "info",
          //   message: `action: ${action}`,
          // });
        },
      });
    },
    // 查看物流
    readLogistic(row) {
      this.$router.push({
        path: "/index/subRouter/logistics",
        query: { id: row.id, orderno: row.orderno, type: "TK" },
      });
    },
    selectable(row) {
      if (row.ck_number == 0) {
        return false;
      } else {
        return true;
      }
    },
    selectionChange(e) {
      console.log("选择商品---", e);
      this.selectionList = e;
    },
    changeReason(e) {
      // console.log( e);
      // console.log("选择原因---", e);
      const selectedItems = this.options.filter((option) => e.includes(option.id));
      // 打印出所有字段信息
      console.log("选择原因---", selectedItems);
      this.formInline.reason_arr = selectedItems;
    },

    successUrl(data) {
      // this.formInline.images.push({ id: data.id });
      this.formInline.image.push(data);
    },
    deleteImage(file) {
      let index = 0;
      this.formInline.image.forEach((e, i) => {
        if (e.id == file.id) index = i;
      });
      this.formInline.image.splice(index, 1);
    },
    onSubmit() {
      // console.log("提交信息---", this.formInline);
      if (!this.selectionList.length) {
        this.$message.error("请选择退货商品");
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formInline.goods = [];
          this.selectionList.forEach((e) => {
            this.formInline.goods.push({ id: e.id, number: e.number1 });
          });
          this.$api("account.applyReturn", { ...this.formInline, id: this.$route.params.id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("申请退货成功");
              this.page = 1;
              this.getReturn();
              this.type = 101;
            }
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getReturn();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getReturn();
    },
    resetForm(formName) {
      this.dialogYundanVisible = false;
      this.$refs[formName].resetFields();
    },
    confirmFn(formName) {
      console.log(this.formLabelAlign);
      // 填写运单号
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交填写的内容
          console.log(this.formLabelAlign);
          this.$api("account.getReturnLogistics", this.formLabelAlign).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success("运单号填写成功");
              this.getReturn();
              this.dialogYundanVisible = false;
            }
          });
        } else {
          console.log("请填写");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 120px;
    top: 0;
    width: 76px;
    height: 30px;
    line-height: 30px;
    background: #ff4c4c;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .btn1 {
    background: @themeColor;
  }
}
.address-info {
  width: 100%;
  padding: 10px 0;
  padding-left: 10px;
  border: 1px solid #0abc64;
  color: #0abc64;
  background-color: #e5f8ef;
  margin-top: 10px;
}
.check {
  cursor: pointer;
  width: 48px;
  height: 30px;
  background: @themeColor;
  border-radius: 4px;
  color: #ffffff;
  .margin-right10 {
    // margin-right: 10rpx;
  }
}

.form_wrap {
  margin-top: 20px;
}
.sub_btn {
  margin-left: 100px;
  width: 76px;
  height: 30px;
  background: #ff4c4c;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
</style>
<style lang="less">
.mytable {
  .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
